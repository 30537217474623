<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <b-link class="brand-logo">
                <b-img
                    :src="appLogoImage"
                    alt="logo"
                    width="220"
                />
            </b-link>

            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>

            <b-col
                lg="4"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >

                    <b-card-title
                        title-tag="h2"
                        class="font-weight-bold mb-1"
                    >
                        Bienvenido(a) a <span class="text-primary">ESSAC ENGINEERING S.A.C</span>! 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        ¡Inicie sesión y comience hacer uso de los diferentes módulos que ofrece la plataforma!
                    </b-card-text>

                    <validation-observer ref="loginForm">

                        <b-form class="auth-login-form mt-2">

                            <!-- EMAIL -->
                            <div class="mb-2">
                                <label for="email"><strong>CORREO ELECTRÓNICO</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="email"
                                        v-model="userEmail"
                                        name="login-email"
                                        autocomplete="login-email"
                                        :state="errors.length > 0 ? false:null"
                                        placeholder="john@example.com"
                                        autofocus
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <!-- PASSWORD -->
                            <div class="mb-2">
                                <label for="password"><strong>CONTRASEÑA</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    name="contraseña"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : null"
                                    >
                                        <b-form-input
                                            id="password"
                                            v-model="password"
                                            :type="passwordFieldType"
                                            class="form-control-merge"
                                            :state="errors.length > 0 ? false : null"
                                            name="login-password"
                                            autocomplete="current-password"
                                            placeholder="Password"
                                        />

                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>

                            <div class="d-flex align-center justify-content-end mb-2">
                                <b-link :to="{ name: 'forgot-password' }">
                                    <small>¿Olvidaste tu contraseña?</small>
                                </b-link>
                            </div>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading
                                text="INICIAR SESIÓN"
                                :processing="processing"
                                :block="true"
                                @process-action="login">
                            </b-button-loading>

                        </b-form>

                    </validation-observer>

                    <!-- DIVIDER -->
                    <div class="divider my-2">
                        <div class="divider-text">REDES SOCIALES</div>
                    </div>

                    <!-- SOCIAL BUTTONS -->
                    <div class="auth-footer-btn d-flex justify-content-center">
                        <b-button
                            variant="facebook"
                            href="https://www.facebook.com/essac.pe"
                            target="_blank"
                        >
                            <feather-icon icon="FacebookIcon" />
                        </b-button>
                        <b-button
                            variant="instagram"
                            href="https://www.instagram.com/essac.pe/"
                            target="_blank"
                        >
                            <feather-icon icon="InstagramIcon" />
                        </b-button>
                        <b-button
                            variant="linkedin"
                            href="https://www.linkedin.com/company/essacperu/"
                            target="_blank"
                        >
                            <feather-icon icon="LinkedinIcon" />
                        </b-button>
                    </div>

                </b-col>
            </b-col>

        </b-row>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { togglePasswordVisibility } from '@core/mixins/ui/forms';
    import { getHomeRouteForLoggedInUser } from '@/auth/utils';
    import { required, email } from '@validations';
    import { $themeConfig } from '@themeConfig';
    import useJwt from '@/auth/jwt/useJwt';
    import store from '@/store';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading
        },
        mixins: [ togglePasswordVisibility ],
        data() {
            return {
                logo: require('@/assets/images/logo/logo.png'),
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                processing: false,

                userEmail: '',
                password: '',

                required,
                email
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark')
                {
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
                    return this.sideImg;
                }

                return this.sideImg;
            }
        },
        methods: {
            login() {
                this.$refs.loginForm.validate().then(success => {
                    if (success) {

                        this.processing = true;

                        useJwt.login({
                            email: this.userEmail,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.success) {

                                const { userData, accessToken, refreshToken } = response.data;

                                useJwt.setToken(accessToken);
                                useJwt.setRefreshToken(refreshToken);

                                localStorage.setItem('userData', JSON.stringify(userData));

                                this.$ability.update(userData.ability);

                                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                                    .then(() => {

                                        this.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: `Bienvenido(a) ${userData.fullName}`,
                                                icon: 'CoffeeIcon',
                                                variant: 'success',
                                                text: `Ha iniciado sesión correctamente como ${userData.roleAlias}. ¡Ahora puedes empezar a explorar la plataforma!`
                                            }
                                        })

                                    });
                            }
                        })
                        .catch(error => this.$refs.loginForm.setErrors({ email: [ error.response.data.message] }))
                        .finally( () => this.processing = false);

                    }
                });
            }
        },
        setup() {
			const { appLogoImage } = $themeConfig.app;

			return {
				appLogoImage
			}
		}
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>
